// Imports

import styles from "./Layout.module.scss"; // Styles

// Types

export default function Layout({ children }) {
	return (
		<div>
			{/* Page content */}
			<div className={styles.content}>{children}</div>
			{/* Bottom footer */}
		</div>
	);
}
