import { Button, Card } from "semantic-ui-react";
import image1 from "./images/1.png";
import image2 from "./images/88.png";
import Layout from "./components/layout/Layout";
import styles from "./App.module.scss"; // Styles
import {
	WalletMultiButton
} from '@solana/wallet-adapter-react-ui';

export default function Home() {
	// TODO: Add discord link
	const quicklinks = [
		{
			name: "Twitter",
			url: "https://twitter.com/sol_stash",
		},
		{
			name: "Discord",
			url: "https://discord.gg/Z9DWzRX7jW",
		},
	];

	const buyButton = () => {
		window.location.href = 'https://digitaleyes.market/collections/Stash'
	};

	const buyMagicEden = () => {
		window.location.href = 'https://www.magiceden.io/marketplace?collection_symbol=stash';
	}

	return (
		<Layout>
			<div>
				<div className={styles.home__cta}>
					{/* CTA title */}
					<h1>SolStash</h1>

					{/* Quicklinks */}
					<ul>
						{quicklinks.map(({ name, url }, i) => {
							return (
								<li key={i}>
									<a href={url} target="_blank" rel="noopener noreferrer">
										{name}
									</a>
								</li>
							);
						})}
					</ul>

					{/* CTA Description */}
					<p>
						SolStash is randomised gear generated and stored on chain.
						<br /> Images and functionality are intentionally omitted for others to interpret.
						<br /> Feel free to use SolStash in any way you want.
					</p>
				</div>
				
				<div className={styles.home__buttons}>
					<div className={styles.home__mint}>
						<Card>
							<Card.Content header="GET SOLSTASH" />

							<Card.Content extra>
								<div className={styles.home__buyButtons}>
									<Button
										size="big"
										fluid
										color={"black"}
										onClick={buyButton}
									>
										Buy from Digital Eyes	
									</Button>
								</div>

								<Button
									size="big"
									fluid
									color={"black"}
									onClick={buyMagicEden}
								>
									Buy from Magic Eden
								</Button>
							</Card.Content>
						</Card>
					</div>
					
				</div>
				
				<div className={styles.home__feature}>
					<span>Example SolStashs:</span>
					<img src={image1} alt="" className={styles.home__images} />
					<img src={image2} alt="" className={styles.home__images} />
				</div>
			</div>
		</Layout>
	);
}
